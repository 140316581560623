<template>
  <div class="navbar">
    <van-nav-bar :title="title"
                 :left-text="leftText"
                 :left-arrow="leftArrow"
                 :right-text="rightText"
                 :border="border"
                 :fixed="fixed"
                 z-index="99999"
                 @click-left="goBackFn"
                 @click-right="goRightFn">

    </van-nav-bar>
  </div>
</template>
<script>
  import { defineComponent, toRefs, reactive, getCurrentInstance } from 'vue'
  import { useRouter, useRoute } from "vue-router";
  import { ua } from '@/utils/ua'
  export default defineComponent({
    name: "navbar",
    props: {
      fixed: {
        default: () => false,
        type: Boolean
      },
      border: {
        default: () => false,
        type: Boolean
      },
      leftArrow: {
        default: () => false,
        type: Boolean
      },
      leftText: {
        default: () => '',
        type: String
      },
      rightText: {
        default: () => '',
        type: String
      },
      rightFn: {
        default: () => new Function(),
        type: Function
      },
      customLeftFn: {
        type: Function
      }
    },
    setup(props, { emit }) {
      const $router = useRouter();
      const $route = useRoute();
      const app = getCurrentInstance();
      let title = $route.meta.title
      const state = reactive({
        title
      })
      const goBackFn = () => {
        if (!props.customLeftFn) {
          if (ua.isAndroidApp || ua.isIosApp) {
            app.proxy.$bridge.handleMethods('goBack')
          }
          else {
            $router.go(-1);
          }
        } else {
          props.customLeftFn();
        }
      }
      const goRightFn = () => {
        props.rightFn();
      }
      return {
        goBackFn,
        goRightFn,
        ...toRefs(state)
      }
    }
  })
</script>

<style lang="scss" scoped>
</style>